import { Route, Routes, useNavigate } from 'react-router';
import { ProtectedLayout } from './Components/Layout';
import { HomePage } from './Pages/Home';
import { LoginPage } from './Pages/Login';
import { CategoriesPage, Activities, RewardsPage } from './Pages/Settings';
import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import OktaAuth, { toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { NoAccessPage } from './Pages/NoAccess';

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/login/callback',
  scopes: ['openid', 'profile', 'email', 'groups'],
  pkce: true,
});

function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route element={<ProtectedLayout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/settings/activities" element={<Activities />} />
          <Route path="/settings/categories" element={<CategoriesPage />} />
          <Route path="/settings/rewards" element={<RewardsPage />} />
        </Route>
        <Route path="/no-access" element={<NoAccessPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/callback" element={<LoginCallback />} />
      </Routes>
    </Security>
  );
}

export default App;

import ClickAwayListener from 'react-click-away-listener';
import {
  Action,
  Activity,
  Category,
  Reward,
  useGetApiCategoryQuery,
  usePostApiActivityMutation,
  usePostApiRewardMutation,
  usePutApiActivityMutation,
  usePutApiRewardMutation,
} from 'src/Services/planetApi';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import { useEffect, useState } from 'react';
import { produce } from 'immer';
import { createPortal } from 'react-dom';
import QRCode from 'react-qr-code';
import * as htmlToImage from 'html-to-image';
import download from 'downloadjs';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

type ComponentProps = {
  activity: Activity;
  onCloseFn: Function;
  readonly: boolean;
};

const ActivityModal = (props: ComponentProps) => {
  const [activity, setReward] = useState(props.activity);
  const [actions, setActions] = useState<Action[]>([]);
  const [updateActivity, updateStatus] = usePutApiActivityMutation();
  const [addActivity, addStatus] = usePostApiActivityMutation();
  const { data: categories } = useGetApiCategoryQuery();

  async function saveModel() {
    const dto = produce(activity, (draft) => {
      draft.actionId = draft.action?.id;
      draft.categoryId = draft.category?.id;
    });
    if (activity.id) {
      await updateActivity({ activity: dto });
    } else {
      await addActivity({
        activity: dto,
      });
    }
    props.onCloseFn();
  }

  function setName(name: string) {
    const updatedDto = produce(activity, (draft) => {
      draft.name = name;
    });
    setReward(updatedDto);
  }

  function setCooldown(cooldown: number) {
    const updatedDto = produce(activity, (draft) => {
      draft.coolDownHours = cooldown;
    });
    setReward(updatedDto);
  }

  function setPoints(points: number) {
    const updatedDto = produce(activity, (draft) => {
      draft.points = points;
    });
    setReward(updatedDto);
  }

  function setCategory(categoryId: string) {
    const updatedDto = produce(activity, (draft) => {
      draft.category = categories?.find(
        (x) => x.id === Number.parseInt(categoryId)
      );
    });
    setReward(updatedDto);
  }

  function setAction(actionId: string) {
    const updatedDto = produce(activity, (draft) => {
      draft.action = draft.category?.actions?.find(
        (x) => x.id === Number.parseInt(actionId)
      );
    });
    setReward(updatedDto);
  }

  async function downloadQrCode() {
    htmlToImage
      .toPng(document.getElementById('qr-code')!)
      .then(function (dataUrl) {
        download(dataUrl, `${activity.name}.png`);
      });
  }

  useEffect(() => {
    const flatActions = categories?.flatMap((x) => x.actions) || [];
    setActions(flatActions as Action[]);
  }, [categories]);

  return createPortal(
    <div className="absolute top-0 left-0 bg-black w-screen h-screen bg-opacity-60 flex items-center">
      <div className="bg-white flex flex-col mx-auto container p-10 rounded-md w-6/12">
        <h1 className="text-2xl font-bold flex items-center">
          {props.activity.id ? 'Edit' : 'Add'} reward
          <XMarkIcon
            className="w-8 ml-auto cursor-pointer"
            onClick={() => props.onCloseFn()}
          />
        </h1>
        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-80">
            <TextField
              id="name"
              label="Name"
              variant="outlined"
              value={activity.name}
              onChange={(e) => setName(e.target.value)}
              disabled={props.readonly}
            />
          </div>
          <div className="flex flex-col w-80 ml-8">
            <FormControl>
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={activity.category}
                label="Category"
                onChange={(e) => setCategory(e.target.value as string)}
              >
                {categories?.map((category, index) => (
                  <MenuItem key={index} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className="flex flex-col w-80 ml-8">
            <FormControl>
              <InputLabel id="action">Action</InputLabel>
              <Select
                labelId="action"
                id="action"
                value={activity.action}
                label="Action"
                onChange={(e) => setAction(e.target.value as string)}
                disabled={!activity.category || !activity.category.id}
              >
                {actions
                  ?.filter((x) => x.categoryId === activity.category?.id)
                  .map((action, index) => (
                    <MenuItem key={index} value={action.id}>
                      {action.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="flex flex-row mt-8">
          <div className="flex flex-col w-80">
            <TextField
              id="cooldown"
              label="Cooldown Hours"
              variant="outlined"
              value={activity.coolDownHours}
              onChange={(e) => setCooldown(Number.parseInt(e.target.value))}
              disabled={props.readonly}
            />
          </div>
          <div className="flex flex-col ml-8 w-80">
            <TextField
              id="points"
              label="Points"
              variant="outlined"
              value={activity.points}
              onChange={(e) => setPoints(Number.parseInt(e.target.value))}
              disabled={props.readonly}
            />
          </div>
        </div>
        <div className="flex flex-col mt-10">
          <label htmlFor="Image" className="text-lg font-medium select-none">
            QR Code
          </label>
          {activity.name && (
            <div>
              <div className="my-4 w-40 h-44 p-4 bg-white" id="qr-code">
                <QRCode value={activity.name!} size={128} />
              </div>
              {props.readonly && (
                <button
                  className="border px-4 py-1 rounded-md border-green-500 bg-green-500 text-white font-bold"
                  onClick={downloadQrCode}
                >
                  Download QR Code
                </button>
              )}
            </div>
          )}
        </div>
        {!props.readonly && (
          <div className="mt-10 flex flex-row">
            <button
              className="ml-auto font-semibold border-gray-400 rounded-md px-4 py-1 select-none"
              onClick={() => props.onCloseFn()}
            >
              Cancel
            </button>
            <button
              className="ml-5 font-semibold rounded-md px-4 py-1 bg-[#6fcf97] hover:bg-[#52cf86] hover:text-gray-900 select-none"
              onClick={saveModel}
              disabled={addStatus.isLoading || updateStatus.isLoading}
            >
              {props.activity.id ? 'Update' : 'Add'}
            </button>
          </div>
        )}
      </div>
    </div>,
    document.getElementById('modal')!
  );
};

export default ActivityModal;

import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  AddButton,
  DeleteButton,
  DetailButton,
  EditButton,
  RefreshButton,
} from 'src/Components/Buttons';
import { ActivityModal } from 'src/Components/Modals';
import { Activity, useGetApiActivityQuery } from 'src/Services/planetApi';

const Activities = () => {
  const [editModel, setEditModel] = useState<Activity | undefined>();
  const [detailModel, setDetailModel] = useState<Activity | undefined>();

  const {
    data: activities,
    isFetching: activityFetching,
    refetch: refreshActivity,
  } = useGetApiActivityQuery();

  // const [deleteReward] = useDeleteApiRewardMutation();

  // async function onDeleteReward(reward: Reward) {
  //   await deleteReward({
  //     reward,
  //   });
  // }

  return (
    <div className="w-full">
      <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
        <div className="flex items-baseline pb-10 border-b-2 px-10">
          <h1 className="text-3xl font-bold">Activities</h1>
          <h4 className="text-lg text-gray-400 font-bold ml-10">
            Los Angeles, U.S.
          </h4>
          <div className="ml-auto">
            <RefreshButton onClick={refreshActivity} />
          </div>
        </div>
        <div className="px-10 bg-gray-50 pb-10">
          <div className="grid grid-cols-6 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
            <div className="col-span-1">Name</div>
            <div className="col-span-1">Points</div>
            <div className="col-span-1">Cooldown Hours</div>
            <div className="col-span-1">Category</div>
            <div className="col-span-1">Action</div>
            <div className="col-span-1 ml-auto">
              <AddButton onClick={() => setEditModel({})} />
            </div>
          </div>
          {activityFetching && (
            <div className="pt-4">
              <Skeleton count={3} />
            </div>
          )}
          {!activityFetching &&
            activities?.map((x, index) => (
              <div
                key={index}
                className="grid grid-cols-6 pt-4 text-gray-600 font-normal items-center"
              >
                <div className="col-span-1">{x.name}</div>
                <div className="col-span-1">{x.points}</div>
                <div className="col-span-1">{x.coolDownHours}</div>
                <div className="col-span-1">{x.category?.name}</div>
                <div className="col-span-1">{x.action?.name}</div>
                <div className="ml-auto">
                  <DetailButton onClick={() => setDetailModel(x)} />
                  <EditButton onClick={() => setEditModel(x)} />
                  {/* <DeleteButton onClick={() => onDeleteReward(x)} /> */}
                </div>
              </div>
            ))}
        </div>
      </div>
      {editModel && (
        <ActivityModal
          activity={editModel!}
          onCloseFn={() => setEditModel(undefined)}
          readonly={false}
        />
      )}
      {detailModel && (
        <ActivityModal
          activity={detailModel!}
          onCloseFn={() => setDetailModel(undefined)}
          readonly={true}
        />
      )}
    </div>
  );
};

export default Activities;

import { useOktaAuth } from '@okta/okta-react';
import { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import {
  AddButton,
  DeleteButton,
  DetailButton,
  EditButton,
  RefreshButton,
} from 'src/Components/Buttons';
import { CategoryModal } from 'src/Components/Modals';
import {
  Category,
  useDeleteApiCategoryMutation,
  useGetApiCategoryQuery,
} from 'src/Services/planetApi';

const CategoriesPage = () => {
  const [editModel, setEditModel] = useState<Category | undefined>();
  const [detailModel, setDetailModel] = useState<Category | undefined>();

  const {
    data: categories,
    isFetching: categoriesFetching,
    refetch: refreshCategories,
  } = useGetApiCategoryQuery();

  const [deleteCategory] = useDeleteApiCategoryMutation();

  async function onDeleteCategory(category: Category) {
    await deleteCategory({
      category,
    });
  }

  return (
    <div className="w-full">
      <div className="border-2 pt-10 w-full rounded-md bg-white shadow-lg">
        <div className="flex items-baseline pb-10 border-b-2 px-10">
          <h1 className="text-3xl font-bold">Categories</h1>
          <h4 className="text-lg text-gray-400 font-bold ml-10">
            Los Angeles, U.S.
          </h4>
          <div className="ml-auto">
            <RefreshButton onClick={refreshCategories} />
          </div>
        </div>
        <div className="px-10 bg-gray-50 pb-10">
          <div className="grid grid-cols-5 pt-10 text-lg text-gray-700 font-medium border-b-2 border-gray-200 pb-2">
            <div className="col-span-1">Name</div>
            <div className="col-span-2">Description</div>
            <div className="col-span-1">Actions</div>
            <div className="col-span-1 ml-auto">
              <AddButton onClick={() => setEditModel({})} />
            </div>
          </div>
          {categoriesFetching && (
            <div className="pt-4">
              <Skeleton count={3} />
            </div>
          )}
          {!categoriesFetching &&
            categories?.map((x, index) => (
              <div
                key={index}
                className="grid grid-cols-5 pt-4 text-gray-600 font-normal items-center"
              >
                <div className="col-span-1">{x.name}</div>
                <div className="col-span-2">{x.description}</div>
                <div className="col-span-1">{x.actions?.length}</div>
                <div className="ml-auto">
                  <DetailButton onClick={() => setDetailModel(x)} />
                  <EditButton onClick={() => setEditModel(x)} />
                  <DeleteButton onClick={() => onDeleteCategory(x)} />
                </div>
              </div>
            ))}
        </div>
      </div>
      {editModel && (
        <CategoryModal
          category={editModel!}
          onCloseFn={() => setEditModel(undefined)}
          readonly={false}
        />
      )}
      {detailModel && (
        <CategoryModal
          category={detailModel!}
          onCloseFn={() => setDetailModel(undefined)}
          readonly={true}
        />
      )}
    </div>
  );
};

export default CategoriesPage;

type ComponentProps = {
  onClick: Function;
};

const RefreshButton = (props: ComponentProps) => {
  return (
    <button
      className="bg-[#6fcf97] px-6 py-3 rounded-md text-gray-800 font-semibold hover:bg-[#52cf86] hover:text-gray-900"
      onClick={() => props.onClick()}
    >
      Refresh
    </button>
  );
};

export default RefreshButton;

import { baseApi as api } from "./baseApi";
export const addTagTypes = [
  "Activity",
  "Category",
  "Reward",
  "Submissions",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getApiActivity: build.query<
        GetApiActivityApiResponse,
        GetApiActivityApiArg
      >({
        query: () => ({ url: `/api/activity` }),
        providesTags: ["Activity"],
      }),
      postApiActivity: build.mutation<
        PostApiActivityApiResponse,
        PostApiActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity`,
          method: "POST",
          body: queryArg.activity,
        }),
        invalidatesTags: ["Activity"],
      }),
      putApiActivity: build.mutation<
        PutApiActivityApiResponse,
        PutApiActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity`,
          method: "PUT",
          body: queryArg.activity,
        }),
        invalidatesTags: ["Activity"],
      }),
      deleteApiActivity: build.mutation<
        DeleteApiActivityApiResponse,
        DeleteApiActivityApiArg
      >({
        query: (queryArg) => ({
          url: `/api/activity`,
          method: "DELETE",
          body: queryArg.activity,
        }),
        invalidatesTags: ["Activity"],
      }),
      getApiCategory: build.query<
        GetApiCategoryApiResponse,
        GetApiCategoryApiArg
      >({
        query: () => ({ url: `/api/category` }),
        providesTags: ["Category"],
      }),
      postApiCategory: build.mutation<
        PostApiCategoryApiResponse,
        PostApiCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category`,
          method: "POST",
          body: queryArg.category,
        }),
        invalidatesTags: ["Category"],
      }),
      putApiCategory: build.mutation<
        PutApiCategoryApiResponse,
        PutApiCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category`,
          method: "PUT",
          body: queryArg.category,
        }),
        invalidatesTags: ["Category"],
      }),
      deleteApiCategory: build.mutation<
        DeleteApiCategoryApiResponse,
        DeleteApiCategoryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/category`,
          method: "DELETE",
          body: queryArg.category,
        }),
        invalidatesTags: ["Category"],
      }),
      getApiReward: build.query<GetApiRewardApiResponse, GetApiRewardApiArg>({
        query: () => ({ url: `/api/reward` }),
        providesTags: ["Reward"],
      }),
      postApiReward: build.mutation<
        PostApiRewardApiResponse,
        PostApiRewardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reward`,
          method: "POST",
          body: queryArg.reward,
        }),
        invalidatesTags: ["Reward"],
      }),
      putApiReward: build.mutation<PutApiRewardApiResponse, PutApiRewardApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/reward`,
            method: "PUT",
            body: queryArg.reward,
          }),
          invalidatesTags: ["Reward"],
        }
      ),
      deleteApiReward: build.mutation<
        DeleteApiRewardApiResponse,
        DeleteApiRewardApiArg
      >({
        query: (queryArg) => ({
          url: `/api/reward`,
          method: "DELETE",
          body: queryArg.reward,
        }),
        invalidatesTags: ["Reward"],
      }),
      getApiSubmission: build.query<
        GetApiSubmissionApiResponse,
        GetApiSubmissionApiArg
      >({
        query: () => ({ url: `/api/submission` }),
        providesTags: ["Submissions"],
      }),
      postApiSubmission: build.mutation<
        PostApiSubmissionApiResponse,
        PostApiSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/submission`,
          method: "POST",
          body: queryArg.submission,
        }),
        invalidatesTags: ["Submissions"],
      }),
      putApiSubmission: build.mutation<
        PutApiSubmissionApiResponse,
        PutApiSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/submission`,
          method: "PUT",
          body: queryArg.submission,
        }),
        invalidatesTags: ["Submissions"],
      }),
      deleteApiSubmission: build.mutation<
        DeleteApiSubmissionApiResponse,
        DeleteApiSubmissionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/submission`,
          method: "DELETE",
          body: queryArg.submission,
        }),
        invalidatesTags: ["Submissions"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as planetApi };
export type GetApiActivityApiResponse = /** status 200 Success */ Activity[];
export type GetApiActivityApiArg = void;
export type PostApiActivityApiResponse = /** status 202 Accepted */ undefined;
export type PostApiActivityApiArg = {
  activity: Activity;
};
export type PutApiActivityApiResponse = /** status 202 Accepted */ undefined;
export type PutApiActivityApiArg = {
  activity: Activity;
};
export type DeleteApiActivityApiResponse = /** status 202 Accepted */ undefined;
export type DeleteApiActivityApiArg = {
  activity: Activity;
};
export type GetApiCategoryApiResponse = /** status 200 Success */ Category[];
export type GetApiCategoryApiArg = void;
export type PostApiCategoryApiResponse = /** status 202 Accepted */ undefined;
export type PostApiCategoryApiArg = {
  category: Category;
};
export type PutApiCategoryApiResponse = /** status 202 Accepted */ undefined;
export type PutApiCategoryApiArg = {
  category: Category;
};
export type DeleteApiCategoryApiResponse = /** status 202 Accepted */ undefined;
export type DeleteApiCategoryApiArg = {
  category: Category;
};
export type GetApiRewardApiResponse = /** status 200 Success */ Reward[];
export type GetApiRewardApiArg = void;
export type PostApiRewardApiResponse = /** status 202 Accepted */ undefined;
export type PostApiRewardApiArg = {
  reward: Reward;
};
export type PutApiRewardApiResponse = /** status 202 Accepted */ undefined;
export type PutApiRewardApiArg = {
  reward: Reward;
};
export type DeleteApiRewardApiResponse = /** status 202 Accepted */ undefined;
export type DeleteApiRewardApiArg = {
  reward: Reward;
};
export type GetApiSubmissionApiResponse =
  /** status 200 Success */ Submission[];
export type GetApiSubmissionApiArg = void;
export type PostApiSubmissionApiResponse = /** status 202 Accepted */ undefined;
export type PostApiSubmissionApiArg = {
  submission: Submission;
};
export type PutApiSubmissionApiResponse = /** status 202 Accepted */ undefined;
export type PutApiSubmissionApiArg = {
  submission: Submission;
};
export type DeleteApiSubmissionApiResponse =
  /** status 202 Accepted */ undefined;
export type DeleteApiSubmissionApiArg = {
  submission: Submission;
};
export type Action = {
  id?: number;
  name?: string | null;
  categoryId?: number;
};
export type Category = {
  id?: number;
  name?: string | null;
  description?: string | null;
  actions?: Action[] | null;
};
export type Activity = {
  id?: number;
  name?: string | null;
  points?: number;
  coolDownHours?: number;
  categoryId?: number;
  actionId?: number;
  category?: Category;
  action?: Action;
};
export type ProblemDetails = {
  type?: string | null;
  title?: string | null;
  status?: number | null;
  detail?: string | null;
  instance?: string | null;
  [key: string]: any;
};
export type Reward = {
  id?: number;
  name?: string | null;
  description?: string | null;
  picture?: string | null;
  points?: number;
  siteId?: number;
};
export type Submission = {
  id?: number;
  text?: string | null;
  createdDate?: string;
  userId?: number;
};
export const {
  useGetApiActivityQuery,
  usePostApiActivityMutation,
  usePutApiActivityMutation,
  useDeleteApiActivityMutation,
  useGetApiCategoryQuery,
  usePostApiCategoryMutation,
  usePutApiCategoryMutation,
  useDeleteApiCategoryMutation,
  useGetApiRewardQuery,
  usePostApiRewardMutation,
  usePutApiRewardMutation,
  useDeleteApiRewardMutation,
  useGetApiSubmissionQuery,
  usePostApiSubmissionMutation,
  usePutApiSubmissionMutation,
  useDeleteApiSubmissionMutation,
} = injectedRtkApi;

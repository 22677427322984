import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { ReactComponent as LogoIcon } from 'src/Assets/logo.svg';
import { MenuNavigation } from 'src/Components/MenuNavigation';

const ProtectedLayout = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      navigate('/login');
    } else {
      if (
        !(authState.idToken?.claims['groups'] as string[]).some((s) =>
          s.startsWith('Okta-PlanetPoints-Admin')
        )
      ) {
        navigate('/no-access/');
      }
    }
  }, [authState]);

  return (
    <div className="p-10 h-full">
      <div className="flex items-center">
        <LogoIcon className="w-80" />
        <div className="ml-4 font-bold">Site Admin</div>
        <MenuNavigation />
      </div>
      <div
        className="flex items-center container absolute top-1/2 left-1/2"
        style={{ transform: 'translate(-50%, -50%)' }}
      >
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;
